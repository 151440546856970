import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import { Box } from "@mui/system";
import { CircularProgress, CssBaseline } from "@mui/material";
import Protected from "./components/Protected/Protected";
import Admin from "./pages/admin/Admin";
import Home from "./pages/home/Home";
import Customers from "./pages/customers/Customers";
import Promotions from "./pages/promotions/Promotions";
import Login from "./pages/login/Login";
import { useCurrentUser } from "./context/user-context";
import Templates from "./pages/templates/Templates";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Cache from "./pages/cache/Cache";
function App() {
  const { isValidating, isAuthenticated, isAdmin } = useCurrentUser();
  return (
    <GoogleOAuthProvider clientId="104182058806-b1ri6qnjk1mjps3gabr8rlcoigki2clr.apps.googleusercontent.com">
      <Box component="main" sx={{ flexGrow: 1, bgcolor: "background.default" }}>
        <CssBaseline />
        {isValidating ? (
          <CircularProgress />
        ) : (
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/admin"
              element={
                <Protected isLoggedIn={isAuthenticated}>
                  <Admin />
                </Protected>
              }
            >
              <Route index element={<Navigate to="/admin/home" replace />} />
              <Route
                path="/admin/home"
                element={isAdmin ? <Home /> : <div>Access denied</div>}
              />
              <Route path="/admin/customers" element={<Customers />} />
              <Route
                path="/admin/promotions"
                element={isAdmin ? <Promotions /> : <div>Access denied</div>}
              />
              <Route
                path="/admin/templates"
                element={isAdmin ? <Templates /> : <div>Access denied</div>}
              />
              <Route
                path="/admin/cache"
                element={isAdmin ? <Cache /> : <div>Access denied</div>}
              />
              <Route path="*" element={<Navigate to="/admin/home" replace />} />
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        )}
      </Box>
    </GoogleOAuthProvider>
  );
}

export default App;
