import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

import { Box } from "@mui/material";
import { useState } from "react";
import { useMutation } from "react-query";
import { httpClient } from "../../util/http";
import {
  useAreYouSureStore,
  useErrorStore,
  useLoadingStore,
  useSuccessStore,
} from "../../state/store";

const Cache = () => {
  const [inputValue, setInputValue] = useState("");
  const [period, setPeriod] = useState("annual");
  const [statement, setStatement] = useState("income-statement");
  const { setAreYouSure } = useAreYouSureStore((state) => state);
  const { setLoading } = useLoadingStore((state) => state);
  const { setSuccess } = useSuccessStore((state) => state);
  const { setError } = useErrorStore((state) => state);

  const clearCache = useMutation(
    (values: string[]) =>
      httpClient.post(`/api/private/cache/clear-symbols`, {
        symbols: values,
      }),
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: () => {
        setLoading(false);
        setSuccess({
          hasSuccess: true,
          successMessage: "Cache cleared",
        });
        setAreYouSure({
          showAreYouSure: false,
          areYouSureMessage: "",
          areYouSureAction: () => {},
        });
        setInputValue("");
      },
      onError: (e) => {
        console.log(e);
        setError({
          hasError: true,
          errorMessage:
            "Something went wrong. Talk to Sheldon and send the console error.",
        });
        setLoading(false);
        setAreYouSure({
          showAreYouSure: false,
          areYouSureMessage: "",
          areYouSureAction: () => {},
        });
      },
    }
  );

  const clearStatement = useMutation(
    (values: { statement: string; period: string }) =>
      httpClient.post(`/api/private/cache/clear-statement`, {
        statement: values.statement,
        period: values.period,
      }),
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: () => {
        setLoading(false);
        setSuccess({
          hasSuccess: true,
          successMessage: "Cache cleared",
        });
        setAreYouSure({
          showAreYouSure: false,
          areYouSureMessage: "",
          areYouSureAction: () => {},
        });
        setInputValue("");
      },
      onError: (e) => {
        console.log(e);
        setError({
          hasError: true,
          errorMessage:
            "Something went wrong. Talk to Sheldon and send the console error.",
        });
        setLoading(false);
        setAreYouSure({
          showAreYouSure: false,
          areYouSureMessage: "",
          areYouSureAction: () => {},
        });
      },
    }
  );

  return (
    <>
      <Toolbar />
      <Box
        sx={{
          p: 2,
        }}
      >
        <Typography variant="body1" sx={{ mb: 2 }}>
          Enter values separated by commas to remove them from the cache. For
          example: "AAPL, AMZN, TSLA"
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            fullWidth
            label="Enter comma-separated values"
            placeholder="value1, value2, value3"
            variant="outlined"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <Button
            variant="outlined"
            onClick={() => {
              if (inputValue.trim() === "") {
                setError({
                  hasError: true,
                  errorMessage: "Please enter values to clear",
                });
                return;
              }
              setAreYouSure({
                showAreYouSure: true,
                areYouSureMessage: "Are you sure you want to clear the cache?",
                areYouSureAction: () =>
                  clearCache.mutate(inputValue.split(",").map((v) => v.trim())),
              });
            }}
            sx={{ minWidth: "100px" }}
          >
            Submit
          </Button>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="period-select-label">Period</InputLabel>
            <Select
              labelId="period-select-label"
              value={period}
              label="Period"
              onChange={(e) => setPeriod(e.target.value)}
            >
              <MenuItem value="annual">Annual</MenuItem>
              <MenuItem value="quarter">Quarter</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="statement-select-label">Statement</InputLabel>
            <Select
              labelId="statement-select-label"
              value={statement}
              label="Statement"
              onChange={(e) => setStatement(e.target.value)}
            >
              <MenuItem value="income-statement">Income Statement</MenuItem>
              <MenuItem value="balance-sheet-statement">
                Balance Sheet Statement
              </MenuItem>
              <MenuItem value="cash-flow-statement">
                Cash Flow Statement
              </MenuItem>
              <MenuItem value="key-metrics">Key Metrics</MenuItem>
              <MenuItem value="financial-growth">Financial Growth</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            onClick={() =>
              setAreYouSure({
                showAreYouSure: true,
                areYouSureMessage: `Are you sure you want to clear ${period} ${statement} data?`,
                areYouSureAction: () =>
                  clearStatement.mutate({
                    statement: statement,
                    period: period,
                  }),
              })
            }
          >
            Clear {period} {statement} data
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Cache;
